import * as React from "react";
const HeroIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={456}
    height={548}
    fill="none"
    {...props}
  >
    <rect
      width={89.7}
      height={89.7}
      x={365.55}
      y={183.15}
      stroke="#5F77F4"
      strokeWidth={1.5}
      rx={15.25}
    />
    <path
      stroke="#5F77F4"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m428.3 210.9-35 35m0-35 35 35"
    />
    <rect
      width={89.7}
      height={89.7}
      x={274.35}
      y={274.35}
      stroke="#5F77F4"
      strokeWidth={1.5}
      rx={15.25}
    />
    <path
      stroke="#5F77F4"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m337.1 302.1-35 35m0-35 35 35"
    />
    <rect
      width={89.7}
      height={89.7}
      x={274.35}
      y={456.75}
      stroke="#5F77F4"
      strokeWidth={1.5}
      rx={15.25}
    />
    <path
      stroke="#5F77F4"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m337.1 484.5-35 35m0-35 35 35"
    />
    <rect
      width={89.7}
      height={89.7}
      x={274.35}
      y={91.95}
      stroke="#5F77F4"
      strokeWidth={1.5}
      rx={15.25}
    />
    <path
      stroke="#5F77F4"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m337.1 119.7-35 35m0-35 35 35"
    />
    <rect
      width={89.7}
      height={89.7}
      x={91.95}
      y={91.95}
      stroke="#5F77F4"
      strokeWidth={1.5}
      rx={15.25}
    />
    <path
      stroke="#5F77F4"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m154.7 119.7-35 35m0-35 35 35"
    />
    <rect
      width={89.7}
      height={89.7}
      x={183.15}
      y={0.75}
      stroke="#5F77F4"
      strokeWidth={1.5}
      rx={15.25}
    />
    <path
      stroke="#5F77F4"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m245.9 28.5-35 35m0-35 35 35"
    />
    <rect
      width={89.7}
      height={89.7}
      x={365.55}
      y={0.75}
      stroke="#F4C16B"
      strokeWidth={1.5}
      rx={15.25}
    />
    <circle cx={410.8} cy={46} r={26.25} stroke="#F4C16B" strokeWidth={1.5} />
    <rect
      width={89.7}
      height={89.7}
      x={183.15}
      y={183.15}
      stroke="#F4C16B"
      strokeWidth={1.5}
      rx={15.25}
    />
    <circle
      cx={228.4}
      cy={228.4}
      r={26.25}
      stroke="#F4C16B"
      strokeWidth={1.5}
    />
    <rect
      width={89.7}
      height={89.7}
      x={0.75}
      y={0.75}
      stroke="#F4C16B"
      strokeWidth={1.5}
      rx={15.25}
    />
    <circle cx={46} cy={46} r={26.25} stroke="#F4C16B" strokeWidth={1.5} />
    <rect
      width={89.7}
      height={89.7}
      x={91.95}
      y={365.55}
      stroke="#F4C16B"
      strokeWidth={1.5}
      rx={15.25}
    />
    <circle
      cx={137.2}
      cy={410.8}
      r={26.25}
      stroke="#F4C16B"
      strokeWidth={1.5}
    />
    <rect
      width={89.7}
      height={89.7}
      x={365.55}
      y={365.55}
      stroke="#F4C16B"
      strokeWidth={1.5}
      rx={15.25}
    />
    <circle
      cx={410.8}
      cy={410.8}
      r={26.25}
      stroke="#F4C16B"
      strokeWidth={1.5}
    />
  </svg>
);
export default HeroIcon;
